<script>
import axios from "axios";
import skeleton from "@/components/custom/skeleton.vue";
import Swal from "sweetalert2";

export default {
  name: "CounterpartyEditCard",
  components: {skeleton},
  props: {
    company_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      company: {
        id: 0,
        name: '',
        phone: '',
        email: '',
        address: '',
        invoice_info: ''
      },
      is_loading_data: false,
      get_request: {
        status: 'loading',
        message: '',
        details: ''
      },
      put_request: {
        status: 'initial',
        message: '',
        details: ''
      },
      mode: 'view',
    }
  },
  methods: {
    async getCounterpartyDetails() {
      this.get_request.status = 'loading'
      try {
        const response = await axios.get(`/customer/companies/${this.$route.params.slug}/`)
        this.company = response.data
      } catch (e) {
        this.get_request.status = 'error'
        try {
          this.get_request.message = e.response.data.detail || 'Something went wrong'
        } catch {
          this.get_request.message = 'Something went wrong'
        }
      }
      this.get_request.status = 'success'
    },
    async saveCounterparty() {
      this.put_request.status = 'loading'
      try {
        await axios.put(`customer/company_update/${this.company.id}/`, {
          name: this.company.name,
          phone: this.company.phone,
          email: this.company.email,
          address: this.company.address,
          invoice_info: this.company.invoice_info,
        })
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${this.capitalizeFirstLetter(this.company_type)} details saved successfully !`,
          showConfirmButton: false,
          timer: 3000
        })
        this.mode = 'view'
        this.put_request.status = 'initial'
        await this.getCounterpartyDetails()
      } catch (e) {
        this.put_request.status = 'error'
        try {
          this.put_request.message = e.response.data.detail || 'Couldn\'t save the details !'
        } catch {
          this.put_request.message = 'Couldn\'t save the details !'
        }
      }
    },
    changeToViewMode() {
      this.mode = 'view'
      this.put_request.status = 'initial'
      this.getCounterpartyDetails()
    },

    capitalizeFirstLetter(string) {
      if (!string) return '-'
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  mounted() {
    this.getCounterpartyDetails()
  }
}
</script>

<template>
  <b-card no-body>
    <b-card-body class="pb-1">
      <b-card-title>{{ capitalizeFirstLetter(company_type) }} Details</b-card-title>
      <form @submit.prevent="saveCounterparty()"
            class="table-responsive pt-1" v-if="['loading', 'success'].includes(get_request.status)">
        <table class="table table-borderless mb-0">
          <tbody>
          <tr class="align-middle">
            <th class="ps-0" scope="row" style="min-width: 70px">
              <div class="d-flex gap-1">
                Name:
                <span v-if="mode === 'edit'" class="text-danger">*</span>
              </div>
            </th>
            <td class="text-muted w-100">
              <skeleton show_data_after_loading :loading="get_request.status === 'loading'">
                <span v-if="mode==='view'">{{ company.name || '-' }}</span>
                <input v-else-if="mode === 'edit'"
                       v-model="company.name"
                       class="form-control" required>
              </skeleton>
            </td>
          </tr>
          <tr class="align-middle">
            <th class="ps-0" scope="row">
              <div class="d-flex gap-1">
                Phone:
                <span v-if="mode === 'edit'" class="text-danger">*</span>
              </div>
            </th>
            <td class="text-muted">
              <skeleton show_data_after_loading :loading="get_request.status === 'loading'">
                <span v-if="mode==='view'">{{ company.phone || '-' }}</span>
                <input v-else-if="mode === 'edit'"
                       v-model="company.phone" type="tel"
                       class="form-control" required>
              </skeleton>
            </td>
          </tr>
          <tr class="align-middle">
            <th class="ps-0" scope="row">
              <div class="d-flex gap-1">
                E-mail:
                <span v-if="mode === 'edit'" class="text-danger">*</span>
              </div>
            </th>
            <td class="text-muted">
              <skeleton show_data_after_loading :loading="get_request.status === 'loading'">
                <span v-if="mode==='view'">{{ company.email || '-' }}</span>
                <input v-else-if="mode === 'edit'"
                       v-model="company.email" type="email"
                       class="form-control" required>
              </skeleton>
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">
              <div class="d-flex gap-1">
                Address:
                <span v-if="mode === 'edit'" class="text-danger">*</span>
              </div>
            </th>
            <td class="text-muted">
              <skeleton show_data_after_loading :loading="get_request.status === 'loading'">
                <span v-if="mode==='view'">{{ company.address || '-' }}</span>
                <textarea v-else-if="mode === 'edit'"
                          v-model="company.address" rows="4"
                          class="form-control" required/>
              </skeleton>
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">
              <div class="d-flex gap-1">
                Invoice info:
                <span v-if="mode === 'edit'" class="text-danger">*</span>
              </div>
            </th>
            <td class="text-muted">
              <skeleton show_data_after_loading :loading="get_request.status === 'loading'">
                <span v-if="mode==='view'">{{ company.invoice_info || '-' }}</span>
                <textarea v-else-if="mode === 'edit'"
                          v-model="company.invoice_info" rows="10"
                          class="form-control" required/>
              </skeleton>
            </td>
          </tr>
          <tr v-if="company.id">
            <td colspan="2" class="ps-0">

              <div v-if="mode === 'edit' && put_request.status === 'error'">
                <b-alert show variant="danger" class="border-0 " role="alert">
                  <strong> {{ put_request.message || 'Couldn\'t save the details !' }} </strong>
                </b-alert>
              </div>

              <b-button v-if="mode === 'view'"
                        @click="mode = 'edit'" type="button"
                        class="w-100" variant="outline-primary">
                Edit Details
                <i class="mdi mdi-pencil"></i>
              </b-button>
              <div v-if="mode === 'edit'" class="d-flex justify-content-between gap-3">
                <b-button @click="changeToViewMode()" variant="light" class="btn-icon waves-effect">
                  <i class="bx bx-arrow-back"></i>
                </b-button>
                <b-button
                    :disabled="put_request.status === 'loading'"
                    :type="put_request.status === 'loading'? 'button' : 'submit'"
                    class="w-100" variant="success">
                  Save Details
                  <i v-if="put_request.status === 'initial'" class="mdi mdi-content-save"></i>
                  <b-spinner class="ms-1" v-else-if="put_request.status === 'loading'"
                             style="width: 15px; height: 15px">
                  </b-spinner>
                </b-button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
      <div v-else-if="get_request.status === 'error'" class="pt-3">
        <b-alert show variant="danger" class="border-0 mb-0 " role="alert">
          <strong>{{ get_request.message || 'Couldn\'t load counterparty details !' }}</strong>
        </b-alert>
      </div>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>